import { useAppState } from '../../state';
import { WarningLogo } from '../Global/Global.styles';
import { OutOfHoursMessageContainer } from './OutOfHoursHeader.styles';

const OutOfHoursHeader = () => {
	const { holidayState } = useAppState();
	return (
		<OutOfHoursMessageContainer>
			<WarningLogo />
			{holidayState === 'holidayDate' ? (
			<div>We are closed 24th-27th December and New Years Day. Bank Holiday hours also apply. Normal service resumes 3rd January.</div>
			) :
			<div>
			Sorry our chat service is currently closed. Please come back between 8am and 10pm on weekdays, 9am and 10pm on Saturdays and 10am and 10pm on Sundays.
			</div>
			}
		</OutOfHoursMessageContainer>
	);
};

export default OutOfHoursHeader;
