import axios from "axios";

/**
 * @param {string} channelSID This is used to use filter through the tasks to get a specific one comparing the ChannelSid within the attributes
 */
async function postTaskAttributes(currentTask: string, newAttributeValue = {}) {
  const currentURL = window.location.hostname;
  const isVertuURL =
    /bristolstreet|vertumotors|macklinmotors|carcreditassured|thetaxicentre|vertumotorcycles|vansdirect|localhost/.test(
      currentURL
    );
  const isDevUrl = /dev.|localhost/.test(currentURL);
  const defaultURL = `www.bristolstreet.co.uk`;
  const defaultDevURL = `dev.bristolstreet.co.uk`;
  const apiURL =
    isVertuURL && !isDevUrl
      ? currentURL
      : isVertuURL && isDevUrl
      ? defaultDevURL
      : defaultURL;
  const twId = isDevUrl
    ? "20221001-jwDkFncSaiYioaxZilac-2e4bf2c3ba74df182eb13b528f4bad2eab959df1"
    : "20221101-WIU8s6jcsiaSVjMSE5EpNDNxU3sMseUH-siUBc8eevKTJFQjgyDoRHZI8k86O161J";

  axios.post(
    `${
      isDevUrl ? "http" : "https"
    }://${apiURL}/content/api/ajax_cust_services.p?Action=twilioUpdateTask&taskId=${currentTask}&twId=${twId}&Attributes=${encodeURIComponent(
      JSON.stringify(newAttributeValue)
    )}`
  );
}

export default postTaskAttributes;
