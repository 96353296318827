import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { useEffect, useState, useRef } from "react";
import { useAppState } from "../../state";
import { FlexWebChatContainerStyle } from "./FlexWebChatContainer.styles";
import OptionButtons from "./CustomButtons/SelectButtons";
import filterTask from "../services/filterTask.service";
import axios from "axios";
import { updateAttributes } from "../../util/updateAttributes";
import blockedList from "../../metadata/blockedUsers";
import { IOption } from "./CustomButtons/SelectButtons/SelectOptions/IOption";
import getObjectByKey from "../../util/getObjectByKey";
import deleteTask from "../services/deleteTask.service";

export type FlexWebChatContainerProps = {
  manager: FlexWebChat.Manager;
};

const currentURL = window.location.hostname;
const isVertuURL =
  /bristolstreet|vertumotors|macklinmotors|carcreditassured|thetaxicentre|vertumotorcycles|vansdirect|localhost/.test(
    currentURL
  );
const isDevUrl = /dev.|localhost/.test(currentURL);
const defaultURL = `www.bristolstreet.co.uk`;
const defaultDevURL = `dev.bristolstreet.co.uk`;
const apiURL =
  isVertuURL && !isDevUrl
    ? currentURL
    : isVertuURL && isDevUrl
    ? defaultDevURL
    : defaultURL;
const twId = isDevUrl
  ? "20221001-jwDkFncSaiYioaxZilac-2e4bf2c3ba74df182eb13b528f4bad2eab959df1"
  : "20221101-WIU8s6jcsiaSVjMSE5EpNDNxU3sMseUH-siUBc8eevKTJFQjgyDoRHZI8k86O161J";

export default function FlexWebChatContainer({
  manager,
}: FlexWebChatContainerProps) {
  const {
    setUiState,
    setChatState,
    setButtonState,
    flexWebChatManager,
    brandName,
  } = useAppState();
  const [mainContainerHeightUnset, setMainContainerHeightUnset] =
    useState(true);
  document
    .querySelector(".Twilio-IconButton.Twilio-MessageInput-SendButton")
    ?.addEventListener("click", handleSubmitClick);
  document
    .querySelector(".Twilio-MessageInputArea-TextArea textarea")
    ?.addEventListener("keyup", handleEnterInput);
  const inputText = document.querySelector(
    ".Twilio-MessageInputArea-TextArea textarea"
  );
  const channelUID = useRef("");
  const currentTask = useRef("");

  async function handleSubmitClick() {
    localStorage.setItem("interacted", "yes");
    setButtonState("hidden");

    setTimeout(() => {
      updateAttributes(flexWebChatManager);
    }, 3000);
  }

  async function handleEnterInput() {
    if (inputText?.innerHTML !== "" || inputText?.innerHTML === null) {
      onkeyup = (e) => {
        if (e.key === "Enter") {
          localStorage.setItem("interacted", "yes");
          setButtonState("hidden");

          setTimeout(() => {
            updateAttributes(flexWebChatManager);
          }, 3000);
        }
      };
    }
  }

  useEffect(() => {
    localStorage.getItem("interacted");
    const button = document.querySelector<HTMLButtonElement>(
      ".Twilio.Twilio-EntryPoint"
    );
    const mainContent = document.querySelector<HTMLButtonElement>(
      ".Twilio.Twilio-MainContainer"
    );

    let optionsData: IOption[] = [];
    const { hostname } = window.location;
    switch (true) {
      case hostname.includes("motorcycle"):
        optionsData = getObjectByKey("motorcycle") || [];
        break;
      case hostname.includes("vansdirect"):
        optionsData = getObjectByKey("vansdirect") || [];
        break;
      case hostname.includes("thetaxicentre"):
        optionsData = getObjectByKey("taxi") || [];
        break;
      default:
        optionsData = getObjectByKey("car") || [];
        break;
    }

    // Hyperlink for transcript
    // FlexWebChat.MessageCanvasTray.Content.add(<RequestButtons key="hyper" data={RequestMetaData} />);
    //
    FlexWebChat.MessageList.Content.add(
      <OptionButtons key="mydata" data={optionsData} />
    );

    if (
      FlexWebChat.MessageList.Content.fragments.length > 1 ||
      FlexWebChat.MessageCanvasTray.Content.fragments.length > 1
    ) {
      FlexWebChat.MessageList.Content.fragments.pop();
      // Resolves issue of duplication selection of the transcript fragment
      // FlexWebChat.MessageCanvasTray.Content.fragments.pop();
    }

    if (!mainContent && button) {
      button.click();
    } else {
      if (manager.store.getState().flex.chat.session.client) {
        setMainContainerHeightUnset(false);
        setChatState("chatting");
        setUiState("chat");
        if (localStorage.getItem("interacted")) {
          setButtonState("hidden");
        }
        if (flexWebChatManager) {
          const { channelSid } =
            flexWebChatManager.store.getState().flex.session;
          channelUID.current = channelSid;
        }
      }
    }

    function closeAgentChat() {
      filterTask(channelUID.current).then((res) => {
        if (!res) return;
        res?.data?.tasks?.forEach((task: any) => {
          if (
            task.assignment_status !== "completed" ||
            task.assignment_status !== "wrapping"
          ) {
            currentTask.current = task.sid;
            axios.post(
              `${
                isDevUrl ? "http" : "https"
              }://${apiURL}/content/api/ajax_cust_services.p?Action=twilioUpdateTask&taskId=${
                currentTask.current
              }&twId=${twId}&AssignmentStatus=wrapping`
            );
          }
        });
      });
    }

    function bse() {
      setMainContainerHeightUnset(false);
      setChatState("loading");
      const name = (
        document.querySelector('input[name="Name"]') as HTMLInputElement
      )?.value;
      const email = (
        document.querySelector('input[name="EmailAddress"]') as HTMLInputElement
      )?.value;
      localStorage.setItem("c2d-forms-name", name);
      localStorage.setItem("c2d-forms-email", email);
      manager.configuration.context.friendlyName = name;
      manager.configuration.context.brandAgent = brandName;
      analytics.ready(function () {
        manager.configuration.context.segAnonId = window.analytics
          .user()
          .anonymousId();
      });
    }

    FlexWebChat.Actions.addListener("beforeStartEngagement", bse);
    function ase() {
      setChatState("chatting");
      if (flexWebChatManager) {
        const { channelSid } = flexWebChatManager.store.getState().flex.session;
        channelUID.current = channelSid;
      }
      localStorage.removeItem("interacted");

      if (blockedList.includes(manager.configuration.context.segAnonId)) {
        FlexWebChat.Actions.invokeAction("RestartEngagement");
      }
    }

    FlexWebChat.Actions.addListener("afterStartEngagement", ase);
    function are() {
      setMainContainerHeightUnset(true);
      setChatState("preengagement");
      closeAgentChat();
      filterTask(channelUID.current).then((res) => {
        if (!res) return;
        res?.data?.tasks?.forEach((task: any) => {
          if (["reserved", "pending"].includes(task.assignment_status)) {
            deleteTask(task.sid);
          }
        });
      });
    }

    FlexWebChat.Actions.addListener("afterRestartEngagement", are);
    function bre() {
      localStorage.removeItem("interacted");
      setButtonState("displayed");
      setMainContainerHeightUnset(true);
    }
    FlexWebChat.Actions.addListener("beforeRestartEngagement", bre);

    return () => {
      FlexWebChat.Actions.removeListener("beforeStartEngagement", bse);
      FlexWebChat.Actions.removeListener("afterStartEngagement", ase);
      FlexWebChat.Actions.removeListener("afterRestartEngagement", are);
      FlexWebChat.Actions.removeListener("beforeRestartEngagement", bre);
    };
  }, [
    brandName,
    flexWebChatManager,
    manager.configuration.context,
    manager.store,
    setButtonState,
    setChatState,
    setUiState,
  ]);

  return (
    <FlexWebChatContainerStyle
      mainContainerHeightUnset={mainContainerHeightUnset}
    >
      <FlexWebChat.ContextProvider manager={manager}>
        <FlexWebChat.RootContainer />
      </FlexWebChat.ContextProvider>
    </FlexWebChatContainerStyle>
  );
}
