import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppStateProvider from './state';

if (window.location.pathname !== '/buy-online/') {
	render(
		<React.StrictMode>
			<AppStateProvider>
				<App />
			</AppStateProvider>
		</React.StrictMode>,
		document.getElementById('vchat_widget')
	);
}

window.addEventListener('startBuyOnline', function buyOnlineClickListener() {
	setTimeout(() => {
		ReactDOM.unmountComponentAtNode(document.getElementById('vchat_widget') as HTMLElement);
		window.removeEventListener('startBuyOnline', buyOnlineClickListener);
	}, 1500);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
