import brandImages from "../metadata/brandImages";

/**
 * Get the current URL and find the matching brand
 * @param {string} currentURL - The current URL
 * @return brand - The matching brand image value
 */
export const setBrandImage = (currentURL: string) => {
  const agentFallbackImg = "https://www.bristolstreet.co.uk/custom/98979.jpg";
  const notifFallbackImg = "https://www.vansdirect.co.uk/custom/122003.jpg";

  const matchingBrand = brandImages.find((brand) =>
    currentURL.includes(brand.brandName)
  );

  return {
    agentImage: matchingBrand?.agentImage ?? agentFallbackImg,
    notificationImage: matchingBrand?.notificationImage ?? notifFallbackImg,
  };
};
