import styled, { css } from 'styled-components';
import { getColour } from '../../App.styles';

export type MenuContainerProps = {
	open: boolean;
};

export const MenuContainer = styled.div<MenuContainerProps>`
	position: fixed;
	right: 0;
	bottom: -700px;
	z-index: 200;
	transition: bottom 0.4s ease-out;
	${(props) =>
		props.open &&
		css`
			bottom: 0;
		`}

	width: 100vw;

	display: flex;
	flex-direction: column;
	padding: 1em;

	background: white;
	border-radius: 16px 16px 0 0;
	box-shadow: 0px 0px 0px 1px #e1e1e1;

	@media (min-width: 768px) {
		max-width: 360px;
		margin-right: 16px;
	}
`;

export const ImageLogo = styled.img`
	max-width: 90px;
	height: 50px;
`;

// export const C2DTextLogo = styled.p`
// 	margin-bottom: 0px;
// 	color: ${getColour().primaryColour};
// 	font-weight: bold;
// `

export type InnerMenuProps = {
	chatting: boolean;
};

export const InnerMenu = styled.div<InnerMenuProps>`
	/* while chat is ongoing remove background and padding */
	background: ${(props) => (props.chatting ? '#fff' : getColour().tertiaryColour)};
	width: 100%;
	padding: ${(props) => (props.chatting ? '0' : '1em')};
	padding-top: 1em;

	overflow-y: auto;

	border-radius: 0px 0px 16px 16px;

	display: flex;
	flex-direction: column;
`;

export const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const AdvisorLogo = styled.img`
	width: 52px;
	height: 52px;
	border-radius: 50px;
`;

export const CTAsContainer = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

export const C2DButton = styled.button`
	padding: 12px;
	text-align: start;
	width: 100%;

	background: white;
	color: ${getColour().primaryColour};

	:hover {
		background: ${getColour().primaryColour};
		color: white;

		i {
			color: white;
		}
	}

	i {
		color: ${getColour().primaryColour};
		margin-right: 10px;
	}

	@media (min-width: 640px) {
		flex: 40%;
	}

	@media (min-width: 768px) {
		flex: 100%;
	}
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 1em;
	right: 1em;

	width: 34px;
	height: 34px;

	background: white;
	border-radius: 50px;
	box-shadow: 0px 0px 4px 1px #e1e1e1cc;
	color: black;

	cursor: pointer;
	background: no-repeat center/12px url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z'/%3E%3C/svg%3E");

`;

export const CallNowButton = styled.button`
	padding: 12px;
	text-align: center;
	width: 100%;

	background: ${getColour().primaryColour};
	color: white;
	cursor: pointer;

	i {
		color: white;
		margin-right: 1em;
	}
`;

export type InputProps = {
	error?: boolean;
};

export const Input = styled.input<InputProps>`
	color: #222;

	border-radius: 16px;
	border: 1px solid #717171;

	${(props) =>
		props.error &&
		css`
			border: 2px solid #d93900;
		`}

	::placeholder {
		color: #717171;
	}
`;

export type MenuOverlayProps = {
	open: boolean;
};

export const MenuOverlay = styled.div<MenuOverlayProps>`
	@media (max-width: 768px) {
		display: ${(props) => (props.open ? 'block' : 'none')};
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.65);
		z-index: 150;
	}
`;
