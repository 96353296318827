import OptionButton from "./SelectOptions";
import { IOption } from "./SelectOptions/IOption";

const OptionButtons = ({ data }: { data: IOption[] }) => {
  return (
    <>
      {data.map((b, i) => (
        <OptionButton key={`button-${i}`} label={b.label} val={b.val} />
      ))}
    </>
  );
};

export default OptionButtons;
