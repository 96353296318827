import OptionMetaData from "../components/FlexWebChatContainer/CustomButtons/SelectButtons/SelectOptions/OptionMetaData";

/**
 * Function to pass the name of the key we wish to target
 * @param {string} key - The key of the object we wisth to target
 * @returns - the object
 */
export default function getObjectByKey(key: string) {
  for (const obj of OptionMetaData) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }
  }
  return null; // Key not found
}
