import * as FlexWebChat from "@twilio/flex-webchat-ui";
import filterTask from "../components/services/filterTask.service";
import { getCurrentBrand } from "./getCurrentBrand";
import postTaskAttributes from "../components/services/postTaskAttributes.service";


export async function updateAttributes(flexWebChatManager: FlexWebChat.Manager | undefined) {
    if (!flexWebChatManager) {
      return;
    }
    const currentURL = window.location.hostname;
    const { channelSid } = flexWebChatManager.store.getState().flex.session;
    const channelUID = channelSid;

    const res = await filterTask(channelUID);

    if (!res) {
      return;
    }

    res?.data?.tasks?.forEach((task: any) => {
        if (task.length !== 0) {
          const currentTask =  task?.sid;
  
          const brand = getCurrentBrand(currentURL);
  
          const customers = {
            customer_attribute_1: window.location.href,
            customer_attribute_2: brand,
          };
  
          const attributesValue = task?.attributes;
          const newAttributeValue = { ...JSON.parse(attributesValue), customers };
    
          postTaskAttributes(currentTask, newAttributeValue);
        }
      });
  }