import styled from "styled-components";
import { getColour } from "../../App.styles";

export type FlexWebChatContainerProps = {
  mainContainerHeightUnset: boolean;
};

export const FlexWebChatContainerStyle = styled.div<FlexWebChatContainerProps>`
  width: 100%;
  position: relative;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;

  .Twilio.Twilio-EntryPoint {
    display: none;
  }

  .Twilio-RootContainer {
    font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  }

  .Twilio.Twilio-MainHeader {
    display: none;
  }

  .Twilio.Twilio-MainContainer {
    position: relative !important;
    max-height: unset !important;
    bottom: unset !important;
    right: unset !important;
    border-radius: 0 !important;
    padding-top: 0 !important;
    height: ${(props) => (props.mainContainerHeightUnset ? "unset" : "400px")};
    width: 100%;

    @media (max-width: 640px) {
      height: ${(props) =>
        props.mainContainerHeightUnset ? "unset" : "360px"};
    }
  }

  .Twilio.Twilio-PreEngagementCanvas {
    padding: 0;
  }

  .Twilio-DynamicForm {
    background: ${getColour().tertiaryColour};
    text-align: start;

    input {
      font-size: 16px;
    }

    .css-1aba35o {
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .Twilio-Button.Twilio-DynamicForm-submit {
    opacity: 1 !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: none;
    background: ${getColour().primaryColour};
    color: #fff;
    width: 100%;
    height: 48px;
    border-radius: 8px;
  }

  .Twilio.Twilio-MessageBubble.css-10rv2ev {
    background: ${getColour().primaryColour};
  }

  .Twilio.Twilio-InlineMessage {
    order: -1;
  }

  .btn-container {
    margin-right: 0;
    margin-left: auto;
    display: block;
    margin-top: 5px;
  }

  .Twilio-MessageBubble-UserName,
  .Twilio-MessageBubble-Time {
    font-size: 9px;
    font-weight: normal;
    color: #bbb;
  }

  .Twilio-MessageBubble-Body {
    font-size: 14px;
    line-height: normal;
    text-align: start;
    order: 1;
    padding-top: 6px;
  }

  .Twilio-MessageBubble-Header {
    order: 2;
    padding-bottom: 5px;
  }

  .Twilio-MessageInputArea-TextArea textarea {
    font-size: 14px;
    max-height: 48px;
    height: 48px;
  }

  .Twilio-MessageListItem-ReadStatus {
    display: none;
  }

  .Twilio-Progress.Twilio-PendingEngagementProgress {
    ::after {
      border-color: ${getColour().circularProgress} transparent transparent
        ${getColour().circularProgress};
    }
  }

  .Twilio-IconButton.Twilio-MessageInput-SendButton {
    height: 48px;
    width: 48px;
    opacity: 1 !important;

    :enabled {
      background-color: ${getColour().sendButton} !important;
    }
  }

  .transcriptBtn {
    background: ${getColour().primaryColour};
    border-radius: 8px;
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 14px;
    line-height: normal;
    font-weight: normal;
    padding: 1em;
    margin-top: 10px;
    font-size: 14px;
    line-height: normal;
    font-family: "proxima-nova", Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
  }

  .Twilio.Twilio-MessageCanvasTray {
    font-family: "proxima-nova", Arial, Helvetica, sans-serif;
    background: ${getColour().tertiaryColour};
    border-radius: 16px;
    padding: 1em;
    text-align: center;

    .MessageCanvasTrayContent {
      margin-top: 10px;

      h6 {
        margin-bottom: 8px;
      }

      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: normal;
      }
    }

    .Twilio-Button {
      background: ${getColour().primaryColour};
      border-radius: 8px;
      width: 100%;
      height: 48px;
      color: #fff;
      font-size: 14px;
      line-height: normal;
      font-weight: normal;
      padding: 1em;
      margin: 0;

      &:after {
        content: "Start new chat";
      }
      span {
        display: none;
      }
    }

    .buttonContainer {
      .btn--small {
        padding: 0.5em 15px;
      }
    }
  }
`;
