import { useState, useRef } from 'react';
import { useAppState } from '../../state';
import { CenterAlignedContainer, Link } from '../Global/Global.styles';
import { ErrorEmailMessage } from './TranscriptChat.styles';
import getChatMessages from '../services/retrieveChat.service';
import axios from 'axios';

const Request = () => {
	const { setUiState, chatState, setChatState, flexWebChatManager: manager, brandName } = useAppState();
	const [emailInput, setEmailInput] = useState('');
	const [invalidEmail, setInvalidEmail] = useState(false);
	let currentMessageId: string;
	const channelUID = useRef('');
	const twId = window.location.href.includes('dev.') 
		? process.env.REACT_APP_DEV_TWID 
		: process.env.REACT_APP_LIVE_TWID;

	const sentTranscript = async () => {
		if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailInput)) {
			setInvalidEmail(false);
			setUiState('confirmation');
			setChatState('sent');

			if (manager) {
				const { channelSid } = manager.store.getState().flex.session;
				channelUID.current = channelSid;

				await getChatMessages(channelUID.current).then((res) => {
					res.data.messages.forEach((item: any) => {
						if (item.from.length === 32) {
							currentMessageId = item.sid;
							const custName = localStorage.getItem('c2d-forms-name');

							axios.post(
								`http://dev.bristolstreet.co.uk/content/api/ajax_cust_services.p?Action=twilioUpdateChat&channelSid=${channelUID.current}&currentMessageId=${currentMessageId}&messageFromName=${custName}&twId=${twId}`
							);
						}
					});
				});
				axios.post(
					`https://sendemail-8752.twil.io/sendEmail?chatChannelSid=${channelUID.current}&emailId=${emailInput}&brandName=${
						brandName.split('Advisor')[0]
					}`
				);
			}
		} else {
			setInvalidEmail(true);
		}
	};

	const handleChange = (e: any) => {
		const val = e.target.value;
		setEmailInput(val);
	};
	return (
		<>
			{chatState === 'transcript' && (
				<CenterAlignedContainer>
					<h4>Request transcript</h4>
					<p>
						If you would like a transcript of this chat emailed to you <br></br>please enter your email address and click the send button
					</p>

					<h4>Enter your email address *</h4>

					<input
						className={invalidEmail === true ? 'form--invalid' : 'emailInput'}
						type="email"
						id="transcriptEmail"
						name="transcriptEmail"
						onChange={handleChange}
					></input>
					{invalidEmail === true && <ErrorEmailMessage>Invalid email</ErrorEmailMessage>}
					<Link onClick={sentTranscript}>
						<i className="fal fa-chevron-left"></i>
						Send email
					</Link>
				</CenterAlignedContainer>
			)}
		</>
	);
};

export default Request;
