import IOptionMetaData from "./IOption";

const OptionMetaData: IOptionMetaData = [
  {
    car: [
      {
        label: `I'm interested in buying a car`,
        val: 1,
      },
      {
        label: `I'd like to sell my car`,
        val: 2,
      },
      {
        label: `I'd like to book in for a service or a repair`,
        val: 3,
      },
      {
        label: `I've got a general question`,
        val: 4,
      },
    ],
    motorcycle: [
      {
        label: `I'm interested in buying a motorcycle`,
        val: 1,
      },
      {
        label: `I'd like to book a test ride`,
        val: 2,
      },
      {
        label: `I'd like to book a service for my motorcycle`,
        val: 3,
      },
      {
        label: `I have a general question`,
        val: 4,
      },
    ],
    taxi: [
      {
        label: `I'm interested in buying a new taxi`,
        val: 1,
      },
      {
        label: `I'm interested in buying a used taxi`,
        val: 2,
      },
      {
        label: `I'm interested in buying an electric taxi`,
        val: 3,
      },
      {
        label: `I've got a general question`,
        val: 4,
      },
    ],
    vansdirect: [
      {
        label: `I'm looking to enquire on a van`,
        val: 1,
      },
      {
        label: `I have a general question`,
        val: 4,
      },
    ],
  },
];

export default OptionMetaData;
