import { useAppState } from "../../state";
import Chat from "../Chat/Chat";
import Request from "../Chat/TranscriptChat";
import Confirmation from "../Chat/SentTranscript";
import OutOfHoursHeader from "../OutOfHoursHeader/OutOfHoursHeader";
import {
  CloseButton,
  ColumnContainer,
  InnerMenu,
  MenuContainer,
  MenuOverlay,
} from "./Menu.styles";
import { setBrandImage } from "../../util/setBrandImage";

// Request and Request hold the screens for the transcript request and the transcript confirmation
export type MenuProps = {
  open: boolean;
  onClose?: () => void;
  onChat?: () => void;
  onCall?: () => void;
  onEnquire?: () => void;
  onVideoCall?: () => void;
};

const Menu = ({ open, onClose }: MenuProps) => {
  const { uiState, chatState } = useAppState();
  const currentUrl = window.location.href;
  const { agentImage } = setBrandImage(currentUrl);

  const closeButtonClick = () => {
    onClose && onClose();
  };

  return (
    <>
      <MenuContainer open={open}>
        <div style={{ width: "30%", height: "2em", alignSelf: "flex-start" }}>
          {window.location.href.includes("carcreditassured") ? (
            <img
              src="https://www.carcreditassured.co.uk/custom/66201.png"
              alt="Car Credit Assured"
            />
          ) : (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1464.2 334.2"
              xmlSpace="preserve"
              role="img"
              aria-hidden="true"
              focusable="false"
            >
              {window.location.href.includes("vansdirect") && (
                <>
                  <defs>
                    <path
                      id="SVGID_1_"
                      d="M277.3 78.6h-31L263 62.5c7.2-7.1 13.1-16.4 13.1-25.8 0-18.1-13.4-26.5-26.9-26.5-13.7 0-27 8.2-27 26.9h16.1c0-7.7 4.5-11.1 11.2-11.1 6.1 0 10.3 3.8 10.3 10.7 0 5.9-4.5 12-9.7 17.2l-27.9 28.3v12.1h55.1V78.6zm35-36.8h-9.2l1.1-14.8H337V11.8h-48l-3.3 45.8h25.7c8.5 0 13.2 4.6 13.2 11.2 0 6.7-4.7 11.2-13.2 11.2s-13.3-4.5-13.3-11.2H282c0 17.7 13.9 27.1 29.5 27.1S341 86.5 341 68.8c-.1-17.7-13.2-27-28.7-27zm81.8 52.5h16.1V57.6l27.1-45.8h-18.4L402.2 42l-16.7-30.2h-18.4l27 45.8v36.7zm63.1-60.6c17.3 0 29.3 13.8 29.3 31.1 0 2.2-.1 4.2-.6 6.2h-44.2c2 8.4 8.4 12.3 15.9 12.3 5.4 0 10.1-2.6 12.1-5.9l15-.1c-3.7 11.9-14 18.6-27.2 18.6-19.2 0-31.6-13.6-31.6-31.1 0-17.6 12.7-31.1 31.3-31.1zm-15.6 25.5h29.7c-1.8-8.5-7.9-11.8-14.3-11.8-8.1 0-13.5 4.3-15.4 11.8zm138.9 6c0-11.4 8.2-15 16.1-14v-17c-7.2 0-13.2 3.1-16.1 10v-8.8h-15.2v58.9h15.2V65.2zm38.1-12.8c0-3.4 3.1-5.3 7.9-5.3 4.4 0 7.8 2.4 7.8 7h14.8c0-13.8-10.8-20.4-22.6-20.4-12.3 0-23.1 6.4-23.1 18.7 0 22.6 31.7 14.6 31.7 24.7 0 3.4-3.1 5.3-8.6 5.3s-9-2.4-9-7h-14.8c0 13.8 10.8 20.4 23.8 20.4s23.8-6.2 23.8-18.7c0-22.7-31.7-14.4-31.7-24.7zm118.2 12.4c0 17.4-13.9 31-31.1 31-17.2 0-31-13.6-31-31s13.8-31 31-31 31.1 13.6 31.1 31zm-10.3 0c0-11.9-9.1-21-20.9-21-11.7 0-20.7 9.1-20.7 21s9.1 21 20.7 21c11.9 0 20.9-9.1 20.9-21zM775 20.1v-9.9c-16.8-1.2-25.7 7.7-25.7 23.3v1.8h-9.5v9.9h9.5v49h10.3v-49H775v-9.9h-15.4v-1.8c0-10.2 5.2-14.2 15.4-13.4zM525 95.9c-17.3 0-31.1-13.6-31.1-31.1 0-17.6 13.8-31.1 31.1-31.1s31.2 13.6 31.2 31.1v29.5H541v-2.7c-4.6 2.7-10.1 4.3-16 4.3zm0-14.8c9.1 0 16-6.7 16-16.3 0-9.5-7-16.3-16-16.3s-15.9 6.7-15.9 16.3c0 9.6 7 16.3 15.9 16.3z"
                    ></path>
                  </defs>
                  <linearGradient
                    id="SVGID_00000092449176849334118070000004361566220496111789_"
                    gradientUnits="userSpaceOnUse"
                    x1="222.183"
                    y1="53.023"
                    x2="775.039"
                    y2="53.023"
                  >
                    <stop offset="0" stopColor="#ccc"></stop>
                    <stop offset="1" stopColor="gray"></stop>
                  </linearGradient>
                  <use
                    xlinkHref="#SVGID_1_"
                    fill="url(#SVGID_00000092449176849334118070000004361566220496111789_)"
                  ></use>
                </>
              )}
              <use xlinkHref="#primaryNavLogo"></use>
            </svg>
          )}
        </div>
        <CloseButton onClick={closeButtonClick} />
        {chatState === "preengagement" && (
          <img
            src={agentImage}
            alt=""
            style={{ margin: "1em 0 0 0", borderRadius: "16px 16px 0px 0px" }}
          />
        )}
        <InnerMenu
          chatting={chatState === "loading" || chatState === "chatting"}
        >
          {chatState === "preengagement" && uiState !== "outOfHours" && (
            <h4>Live Chat</h4>
          )}
          <ColumnContainer>
            {uiState === "chat" && <Chat />}
            {uiState === "confirm" && <Request />}
            {uiState === "confirmation" && <Confirmation />}
            {uiState === "outOfHours" && <OutOfHoursHeader />}
          </ColumnContainer>
        </InnerMenu>
      </MenuContainer>
      <MenuOverlay open={open} />
    </>
  );
};

export default Menu;
