import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { useEffect, useState } from "react";
import {
  FixedChatButton,
  FixedChatButtonIcon,
  FixedChatButtonIconContainer,
  FixedChatButtonText,
  FloatingButton,
  Notification,
  NotificationClose,
  NotificationCloseAvatar,
  NotificationCloseIcon,
} from "./App.styles";
import Menu from "./components/Menu/Menu";
import { flexWebchatConfig } from "./config/flexWebchatConfig";
import { useAppState } from "./state";
import { setBrandImage } from "./util/setBrandImage";

const hostname = window.location.hostname;
// Add domains which need the custom chat bubble icon and text
const hasCustomChat = /vansdirect|localhost/.test(hostname);
const hasFixedSideChat = /vansdirect|localhost/.test(hostname);
const { notificationImage } = setBrandImage(hostname);

function App() {
  const { setFlexWebChatManager, setChatState, setUiState, brandName } =
    useAppState();
  const [showMenu, setShowMenu] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  function showNotification() {
    setIsVisible(true);
    setTimeout(closeNotification, 30000); // 30 seconds
  }

  function closeNotification() {
    setIsVisible(false);
    setTimeout(showNotification, 30000);
  }

  function resetNotification() {
    setIsVisible(false);
    setTimeout(showNotification, 60000);
  }

  useEffect(() => {
    // If the site has the custom chat bubble with the notification,
    // then set the timeout for it
    if (hasCustomChat || hostname.includes("thetaxicentre")) {
      setTimeout(showNotification, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const strokeColors = new Map([
    ["bristolstreet", "#fff"],
    ["macklinmotors", "#fff"],
    ["vertumotors", "#013a3c"],
    ["carcreditassured", "#fff"],
    ["vertumotorcycles", "#fff"],
    ["thetaxicentre", "#fff"],
    ["vansdirect", "#fff"],
    ["localhost", "#fff"],
  ]);

  const strokeSetter = () => {
    const defaultColor = "#fff";

    return {
      strokeColor: strokeColors.get(hostname) || defaultColor,
    };
  };

  useEffect(() => {
    if (brandName.length > 0) {
      const instantiateManager = async () => {
        try {
          const manager = await FlexWebChat.Manager.create(flexWebchatConfig);
          manager.strings.PredefinedChatMessageBody = `Hi there! You are chatting to ${
            brandName.split("Advisor")[0]
          }. How can we help you today? Please choose from the options below or tell us more.`;
          manager.strings.PredefinedChatMessageAuthorName = `${brandName}`;

          setFlexWebChatManager(manager);

          const client = manager.store.getState().flex.chat.session.client;
          if (client) {
            setChatState("chatting");
            setUiState("chat");
            setShowMenu(true);
          }
        } catch (error) {
          console.log(error);
        }
      };

      // Store all the chat triggers so we can init
      // the chat from outside the app
      const webchatTriggers = document.querySelectorAll(".js-chat-trigger");
      if (webchatTriggers) {
        webchatTriggers.forEach((trigger) => {
          trigger.addEventListener("click", () => {
            setShowMenu(true);
          });
        });
      }

      instantiateManager();
    }
  }, [setFlexWebChatManager, setChatState, setUiState, setShowMenu, brandName]);

  const floatingButtonClick = () => {
    setShowMenu(true);
  };

  const chatBubbleCustom = (
    <svg
      style={{ width: "1.75rem", height: "1.5rem" }}
      className="icon"
      role="img"
      aria-label="Chat Icon"
      viewBox="0 0 640 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={strokeSetter()?.strokeColor}
        d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"
      />
    </svg>
  );

  const chatBubbleDefaultIcon = (
    <svg
      className="icon"
      role="img"
      aria-label="Chat Icon"
      viewBox="0 0 26.843 26.843"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(-1.5 -1.5)"
        d="M26.843,14.259a11.1,11.1,0,0,1-1.192,5.034,11.259,11.259,0,0,1-10.067,6.226,11.1,11.1,0,0,1-5.034-1.192L3,26.843l2.517-7.55a11.1,11.1,0,0,1-1.192-5.034A11.259,11.259,0,0,1,10.55,4.192,11.1,11.1,0,0,1,15.584,3h.662a11.233,11.233,0,0,1,10.6,10.6Z"
        fill="none"
        stroke={strokeSetter()?.strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      ></path>
    </svg>
  );

  return (
    <>
      {hasFixedSideChat && !showMenu && (
        <FixedChatButton
          className="chat-bubble chat-bubble--secondary chat-bubble--fixed-left text--uppercase hide--mobile-down"
          onClick={floatingButtonClick}
        >
          <FixedChatButtonIconContainer className="chat-bubble__icon margin-right-10">
            <FixedChatButtonIcon
              className="icon"
              aria-label="Chat Icon"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill={strokeSetter()?.strokeColor}
                d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"
              />
            </FixedChatButtonIcon>
          </FixedChatButtonIconContainer>
          <FixedChatButtonText className="chat-bubble__text">
            Chat with us
          </FixedChatButtonText>
        </FixedChatButton>
      )}
      <div className="chat-bubble-container">
        <Notification
          className={`chat-bubble__notification${
            isVisible ? "" : " hide__default"
          }`}
        >
          <NotificationCloseAvatar className="chat-bubble__avatar img--circle">
            <img src={notificationImage} alt="Customer Service Advisor" />
          </NotificationCloseAvatar>
          <div className="chat-bubble__notification-content text-align--left">
            <h5 className="heading--grouped">Chat with us</h5>
            <p className="font--regular text-size--smaller line-height--smaller margin-bottom-0">
              Our specialist team are available to help
            </p>
          </div>
          <NotificationClose
            type="button"
            onClick={resetNotification}
            className="chat-bubble__close padding-0"
          >
            <NotificationCloseIcon className="icon">
              <use xlinkHref="#globalCancel" />
            </NotificationCloseIcon>
          </NotificationClose>
        </Notification>
        <FloatingButton
          isCustomButton={hasCustomChat}
          flashing={false}
          onClick={floatingButtonClick}
        >
          <FixedChatButtonIconContainer
            className={`chat-bubble__icon${
              chatBubbleCustom ? " margin-bottom-5" : ""
            }`}
          >
            {hasCustomChat ? chatBubbleCustom : chatBubbleDefaultIcon}
          </FixedChatButtonIconContainer>
          {hasCustomChat && (
            <FixedChatButtonText
              smallText={true}
              className="chat-bubble__text text--uppercase"
            >
              Chat
            </FixedChatButtonText>
          )}
        </FloatingButton>
        <Menu open={showMenu} onClose={() => setShowMenu(false)} />
      </div>
    </>
  );
}

export default App;
