import { IOption } from "./IOption";
import { useAppState } from "../../../../../state";
import { updateAttributes } from "../../../../../util/updateAttributes";

export default function OptionButton({ label, val }: IOption) {
  const { flexWebChatManager, buttonState, setButtonState } = useAppState();

  function handleButtonClick(channelSid: string, label: string) {
    flexWebChatManager?.chatClient
      .getChannelBySid(channelSid)
      .then((channel) => {
        channel.sendMessage(label);
        setButtonState("hidden");
        localStorage.setItem("interacted", "yes");
      });
  }

  function handleClick() {
    if (flexWebChatManager) {
      const { channelSid } = flexWebChatManager.store.getState().flex.session;

      setTimeout(() => {
        updateAttributes(flexWebChatManager);
      }, 3000);

      switch (true) {
        case val === 1:
          handleButtonClick(channelSid, label);
          break;
        case val === 2:
          handleButtonClick(channelSid, label);
          break;
        case val === 3:
          handleButtonClick(channelSid, label);
          break;
        case val === 4:
          handleButtonClick(channelSid, label);
          break;
        default:
          console.warn(`There is no val found`);
          break;
      }
    }
  }

  return (
    <div className="btn-container">
      {buttonState === "displayed" ? (
        <div className="buttonContainer">
          <button
            className="btn btn--outline btn--small btn--with-margin-bottom"
            onClick={handleClick}
          >
            {label}
          </button>
        </div>
      ) : null}
    </div>
  );
}
