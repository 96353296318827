import styled from "styled-components";

export type FloatingButtonProps = {
  flashing: boolean;
  isCustomButton?: boolean;
};

export type FixedChatButtonTextProps = {
  smallText?: boolean;
};

export const getColour = () => {
  const commonColours = {
    floatingButton: "#007EDA",
    primaryColour: "#193f77",
    tertiaryColour: "#e0ebfa",
    rgbaLight: "rgba(1, 126, 219, 0.7)",
    rgbaStandard: "rgba(1, 126, 219, 0)",
    sendButton: "#1976d2",
    circularProgress: "rgb(25, 118, 210)",
  };

  const hostname = window.location.hostname;

  if (hostname.includes("vertumotors")) {
    return {
      ...commonColours,
      floatingButton: "#00e2c9",
      primaryColour: "#17A3A3",
      circularProgress: "#17A3A3",
      tertiaryColour: "#f4f4f4",
    };
  }

  if (hostname.includes("vertumotorcycles")) {
    return {
      ...commonColours,
      floatingButton: "#CB0000",
      primaryColour: "#CB0000",
      sendButton: "#CB0000",
      tertiaryColour: "#f4f4f4",
    };
  }

  if (hostname.includes("carcreditassured")) {
    return commonColours; // No additional changes needed
  }

  if (hostname.includes("thetaxicentre")) {
    return {
      ...commonColours,
      floatingButton: "#FF5A5F",
      primaryColour: "#FF5A5F",
      sendButton: "#FF5A5F",
      tertiaryColour: "#f4f4f4",
    };
  }

  if (hostname.includes("vansdirect")) {
    return {
      ...commonColours,
      floatingButton: "#d10911",
      primaryColour: "#d10911",
      sendButton: "#d10911",
      tertiaryColour: "#f4f4f4",
      circularProgress: "rgb(209, 9, 17)",
    };
  }

  if (hostname.includes("localhost")) {
    return commonColours;
  }

  // Default case
  return commonColours;
};

export const FloatingButton = styled.div<FloatingButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.125rem;
  background: ${getColour().floatingButton};
  cursor: pointer;
`;

export const FixedChatButton = styled.button`
  left: 0;
  width: 10.375rem;
  border-radius: 0;
  display: flex;
  align-items: center;
  top: 50%;
  margin: 0;
  height: 2.25rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  background: ${getColour().floatingButton};
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  transform-origin: left center;
  transform: translateY(-50%) rotate(90deg) translate(-50%, -50%);
`;

export const FixedChatButtonIconContainer = styled.div`
  height: 1.75rem;
  width: 1.75rem;
`;

export const FixedChatButtonIcon = styled.svg`
  position: relative;
`;

export const FixedChatButtonText = styled.div<FixedChatButtonTextProps>`
  color: #fff;
  flex: 0 0 auto;

  ${({ smallText }) => smallText && "font-size: 0.625rem;"}
`;

export const Notification = styled.div`
  position: absolute;
  background: white;
  bottom: 100%;
  right: 0;
  width: 100%;
  min-width: 16rem;
  display: flex;
  border-radius: 0.375rem;
  padding: 1.25rem 0.875rem;
  align-items: center;
  filter: drop-shadow(0px 0.3125rem 0.9375rem #a0a0a0);
  margin-bottom: 2rem;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.75rem 0.5625rem 0 0.5625rem;
    border-color: white transparent transparent transparent;
    transform: rotate(0deg);
    bottom: -0.75rem;
    right: 1.125rem;
  }
`;

export const NotificationClose = styled.button`
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.0625rem solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
`;

export const NotificationCloseIcon = styled.svg`
  width: 0.75rem;
  height: 0.75rem;
`;

export const NotificationCloseAvatar = styled.div`
  margin-right: 0.875rem;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
`;
