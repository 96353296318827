import axios from "axios";

/**
 * @param {string} TaskID This returns a specific task
 */
async function deleteTask(taskID: string) {
  const currentURL = window.location.hostname;
  const isVertuURL =
    /bristolstreet|vertumotors|macklinmotors|carcreditassured|thetaxicentre|vertumotorcycles|vansdirect|localhost/.test(
      currentURL
    );
  const isDevUrl = /dev.|localhost/.test(currentURL);
  const defaultURL = `www.bristolstreet.co.uk`;
  const defaultDevURL = `dev.bristolstreet.co.uk`;
  const apiURL =
    isVertuURL && !isDevUrl
      ? currentURL
      : isVertuURL && isDevUrl
      ? defaultDevURL
      : defaultURL;
  const twId = isDevUrl
    ? "20221001-jwDkFncSaiYioaxZilac-2e4bf2c3ba74df182eb13b528f4bad2eab959df1"
    : "20221101-WIU8s6jcsiaSVjMSE5EpNDNxU3sMseUH-siUBc8eevKTJFQjgyDoRHZI8k86O161J";

  const response = axios.delete(
    `${
      isDevUrl ? "http" : "https"
    }://${apiURL}/content/api/ajax_cust_services.p?Action=twilioDeleteTask&taskId=${taskID}&twId=${twId}`
  );

  return response;
}

export default deleteTask;
