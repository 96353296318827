import * as FlexWebChat from '@twilio/flex-webchat-ui';
import { useAppState } from '../../state';
import FlexWebChatContainer from '../FlexWebChatContainer/FlexWebChatContainer';
import { CenterAlignedContainer, Link, Spinner } from '../Global/Global.styles';
const Chat = () => {
	const { setUiState, chatState, flexWebChatManager: manager, setButtonState } = useAppState();
	const backLinkClick = () => {
		setUiState('chat');
		FlexWebChat.Actions.invokeAction('RestartEngagement');
		localStorage.removeItem('interacted');
		setButtonState('displayed');
	};

	return (
		<>
			{manager ? (
				<CenterAlignedContainer>
					<FlexWebChatContainer manager={manager} />
					{chatState === 'chatting' && (
						<Link onClick={backLinkClick}>
							<i className="fal fa-chevron-left"></i>
							End chat
						</Link>
					)}
				</CenterAlignedContainer>
			) : (
				<div style={{ position: 'relative', height: '280px' }}>
					<Spinner>
						<div />
					</Spinner>
				</div>
			)}
		</>
	);
};
export default Chat;
