/**
 * Get the current URL and map the brand properties
 * @param {string} currentURL - The current URL
 * @return brand - The mapped brand
 */
export const getCurrentBrand = (currentURL: string) => {
  // Map the available brands
  const brandMap: { [key: string]: string } = {
    bristolstreet: "BSM",
    macklinmotors: "MM",
    vertumotors: "VM",
    vertumotorcycles: "VMC",
    vansdirect: "VD",
    carcreditassured: "CCA",
    taxicentre: "TTC",
    localhost: "LH",
  };

  const matchingKey = Object.keys(brandMap).find((key) =>
    currentURL.includes(key)
  );

  const brand = matchingKey ? brandMap[matchingKey] : "Undefined";

  return brand;
};
