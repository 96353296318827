const blockedList = [
  "92107000-8be1-47d9-99c9-77291ecefec7",
  "563131e4-3f59-47f9-b8b6-06e23d00eae7",
  "e4ac2c18-1d85-4b06-8c71-15fda31d59bb",
  "8b5a01e5-5400-43b2-855a-b3af8f354d5e",
  "b54d4e8e-df37-4ffb-be76-49768ff5df11",
  "a4e68a2c-8cb7-45c2-a2bb-2a3ddadbda92",
  "da98f90c-cd83-4f59-871a-bdbabaf059a3",
  "1bbb8a97-ce83-4b20-bb27-5d0dc846e663",
  "35d0277f-1edc-4cd3-a263-5e15c19f6fa3",
  "79f827d5-14d0-423c-ad9b-3fb4674334fd",
  "c5f0a2c5-26b0-4ee3-a19f-1fb4217c7cc5",
  "d4d71486-a242-425e-9f87-c0a933482664",
  "d79e1e9a-5797-48d8-b42c-210ad71ed9f5",
  "04bb0e77-8fd3-4eb5-b49e-230787d0501f",
  "dda3bb16-b6b0-4573-b6d2-2c1fca459706",
  "4910b5d0-fb6c-48cd-a10e-59da82aec753",
  "0a9a4590-d1ee-4281-bc2a-15afda69217e",
  "c934dfb8-e188-4efa-9b5c-16174a262931",
  "67de250d-595b-42c7-abfd-1ae71aaa918e",
  "b515eef2-05cc-469b-8533-bcc7a24a5aa",
  "bd19f367-4c11-4c9e-809d-2844af241d53",
];

export default blockedList;
