import styled from 'styled-components';
import { getColour } from '../../App.styles';

// const getColour = () => {
// 	switch(window.location.hostname) {
// 		case 'www.bristolstreet.co.uk':
// 		case 'dev.bristolstreet.co.uk':
// 			return '#193f77';
// 		case 'www.vertumotors.com':
// 		case 'dev.vertumotors.com':
// 			return '#17A352';
// 		case 'localhost':
// 			return '#17A352'
// 		default:
// 			return '#193f77';
// 	}
// }

export const CenterAlignedContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Spinner = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(224, 235, 250, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		display: inline-block;
		width: 70px;
		height: 70px;
		border: 3px solid rgba(25, 63, 119, 0.4);
		border-radius: 50%;
		border-top-color: #193f77;
		animation: spin 1s ease-in-out infinite;
		-webkit-animation: spin 1s ease-in-out infinite;
	}
`;

export const Link = styled.div`
	color: ${getColour().primaryColour};
	cursor: pointer;
	width: fit-content;
	padding: 4px 4px 4px 0;

	i {
		margin-right: 4px;
		font-size: 14px;
		font-weight: 700;
	}
`;

export const H4 = styled.h4`
	text-align: start;
`;

export const WarningLogo = styled.i`
	:after {
		font-size: 50px;
		font-weight: 100;
		content: '\f06a';
		font-family: 'Font Awesome 5 Pro';
	}
`;
