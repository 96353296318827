import * as FlexWebChat from '@twilio/flex-webchat-ui';
import { useAppState } from '../../state';
import { CenterAlignedContainer, Link } from '../Global/Global.styles';

const Confirmation = () => {
	const { setUiState, chatState, setChatState, setButtonState } = useAppState();

	const backLinkClick = () => {
		setUiState('chat');
		setChatState('preengagement');
		FlexWebChat.Actions.invokeAction('RestartEngagement');
		localStorage.removeItem('interacted');
		setButtonState('displayed');
	};

	return (
		<>
			<h4>Transcript has been sent</h4>
			{chatState === 'sent' && (
				<CenterAlignedContainer>
					<Link onClick={backLinkClick}>
						<i className="fal fa-chevron-left"></i>
						End chat
					</Link>
				</CenterAlignedContainer>
			)}
		</>
	);
};

export default Confirmation;
